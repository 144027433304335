<template>
  <div class="user-page" v-if="userinfo">
    <div class="userinfo">
      <img src="https://res.oodcd.cn/qq/defaultAvatar.png" class="avatar" alt="">
      <div>
        <p class="nickname">测试账号昵称</p>
        <p class="uid">458984325</p>
      </div>
    </div>

    <div class="quantity-wrap">
      <header>我的加速流量</header>
      <p class="quantity"> <img src="@/assets/energy.png" class="energy"> {{ allQuantity | bytesToSize }}</p>
    </div>

    <div class="pay-dialog">
      <template v-if="step == 1">
        <h3>预加速流量购买</h3>
        <p class="tip">预加速流量仅预加速文件时使用，预加速失败全额退还，请放心购买</p>
        <div class="package-wrap">
          <div 
            class="package-item" 
            v-for="item in packages" 
            :key="item.pid"
            :class="{'active': pack.pid == item.pid}"
            @click="choosePackage(item)"
          >
            <label class="extra" v-if="item.extra">{{item.extra}}</label>
            <p class="quantity">{{item.quantity | bytesToSize }}</p>
            <p class="gift">加赠:{{item.giftQuantity | bytesToSize }}</p>
            <p class="duration">有效期:{{item.duration }}天</p>
            <p class="price">
              ￥<span class="first">{{`${item.price}`.split('.')[0]}}</span>.<span class="second">{{`${item.price}`.split('.')[1] || '00'}}</span> 
            </p>
          </div>
        </div>
        <van-button type="primary" round block @click="test">立即支付</van-button>
      </template>
      <template v-if="step == 2">
        <img src="https://res.the4can.com/deming/images/pay.png" class="image">
        <div class="button-wrap">
          <van-button plain type="primary" round @click="close">放弃支付</van-button>
          <van-button type="primary" round @click="checkPay">我已支付</van-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* global WeixinJSBridge */
import { createOrder, queryOrder } from '@/api/pay'
export default {
  data () {
    return {
      pack: '',
      step: 1,
      show: true,
      timer: null,
      tradeno: '',
      packages: [],
    }
  },
  async created () {
    this.packages = [
      {
        pid: 1,
        price: 9.99,
        duration: 30,
        quantity: 30 * 1024 * 1024 * 1024,
        giftQuantity: 5 * 1024 * 1024 * 1024,
      },
      {
        pid: 2,
        price: 19.99,
        duration: 90,
        quantity: 100 * 1024 * 1024 * 1024,
        giftQuantity: 20 * 1024 * 1024 * 1024,
        extra: '热'
      },
      {
        pid: 3,
        price: 59.99,
        duration: 365,
        quantity: 1024 * 1024 * 1024 * 1024,
        giftQuantity: 100 * 1024 * 1024 * 1024,
      },
    ]

    this.choosePackage(this.packages[0])
  },
  methods: {
    choosePackage(pack) {
      this.pack = pack
    },
    close () {
      this.step = 1
      this.show = true
      this.timer && clearInterval(this.timer)
      this.hidePayDialog()
    },
    test() { 
      this.$toast('支付功能申请中，暂时无法支付')
    },
    async pay() {
      const res = await createOrder({ pid: this.pack.pid, paytype: 'jsapi' })
       if (res.code == 0) {
        this.tradeno = res.data.tradeno
        this.$toast.loading('正在调取支付窗口')
        const {appId, timeStamp, signType, paySign, nonceStr } = res.data
        WeixinJSBridge.invoke('getBrandWCPayRequest', 
          { 
            appId, 
            timeStamp, 
            package: res.data.package, 
            signType, 
            paySign, 
            nonceStr,
          })
        this.step = 2
        this.checkOrderStatus({ tradeno: this.tradeno })
      } else {
        this.$toast('请稍后重试')
      }
    },
    checkOrderStatus (tradeno) {
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(async () => {
        const res = await queryOrder({ tradeno })
        if (res.code == 0) {
          this.$toast.success('支付成功')
          await this.updateQuantity()
          this.close()
        }
      }, 1000)
    },
    async checkPay () {
      const res = await queryOrder({ tradeno: this.tradeno })
      if (res.code == 0) {
        this.$toast.success('支付成功')
        await this.updateQuantity()
        this.close()
      } else {
        this.$toast.fail('未检测到支付')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.user-page {
  .userinfo {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    background-color: #fff;
    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      border-radius: 50%;
      border: 1px solid #eee;
    }
    .nickname {
      font-size: 16px;
    }
    .uid {
      color: #999;
      font-size: 11px;
    }
  }
  .quantity-wrap {
    margin-top: 20px;
    padding: 20px 0;
    text-align: center;
    border-radius: 4px;
    background-color: #fff;
    header {
      color: #666;
    }
    .quantity {
      font-size: 30px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .energy {
      height: 22px;
      border-radius: 50%;
      margin-right: 0px;
    }
    .van-button {
      height: 30px;
      padding: 8px 30px;
      border-radius: 2px;
    }
  }
}

.pay-dialog {
  margin-top: 20px;
  padding: 20px 10px;
  text-align: center;
  background-color: #fff;
  h3 {
    margin-bottom: 5px;
  }
  .tip {
    font-size: 12px;
    color: #999;
    padding: 0 20px;
    margin-bottom: 10px;
  }

  .van-button {
    margin-top: 15px;
  }
}

.package-wrap {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  .package-item {
    cursor: pointer;
    padding: 10px;
    padding-top: 12px;
    text-align: center;
    width: 32%;
    border: 2px solid #eee;
    border-radius: 4px;
    transition:border ease 0.1s;
    position: relative;
    &.active {
      border-color: $theme-color;
      background-color: $theme-color;
      color: #fff;
      .gift, .duration {
        color: #fff;
      }
    }
  }
  .quantity {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 6px;
  }
  .gift {
    color: #666;
    font-size: 13px;
    margin-bottom: 2px;
  }
  .duration {
    font-size: 12px;
    color: #666;
  }
  .price {
    margin-top: 5px;
    .first {
      font-size: 20px;
    }
    .second {
      font-weight: bold;
    }
  }
  .extra {
    position: absolute;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
    background-color: $danger-color;
    top: -10px;
    right: -2px;
    font-size: 11px;
    padding: 3px 6px;
  }
}

.image {
  padding: 40px;
  width: 280px;
}

.button-wrap {
  padding:15px 10px;
  display: flex;
  justify-content: space-between;
  .van-button {
    width: 45%;
    height: 40px;
  }
}
</style>
